<template>
  <div class="step1container">
    <div class="inputSNBox">
      <span> 您的设备号: Deeper-Wire-DPRB- </span>
      <div >
        <input @change="changeSN" v-model="sn" style="height: 30px; width: 80%" type="text">
      </div>
      
    </div>
    <div style="font-size: 12px; text-align:center; margin-top: 10px;">
      示例: Deeper-Wire-DPRB-9c86365b97ee|caa104a3bc7a4142
    </div>
    <div style="padding: 30px; text-align: center">
      <div style="margin-bottom: 20px;">
        打开设备后台：<a target="_blank" href="http://34.34.34.34"> 34.34.34.34 </a>; 在登录框下面就有设备号。
      </div>
      <img @click="openImg" style="width: 100%;" :src="require('@/assets/loginCode.png')" alt="">
    </div>
    <div style="text-align: right; padding: 0 30px;">
      <van-button type="primary" @click="nextStep">下一步</van-button>
    </div>
  </div>
</template>

<script>
const loginCodeImg = require('@/assets/loginCode.png')
export default {
  data() {
    return {
      sn: '',
      loginCodeImg,
      finalSN: ''
    }
  },
  methods: {
    openImg() {
      window.open(loginCodeImg)
    },
    changeSN() {
      this.sn = this.sn.trim().replace("Deeper-Wire-DPRB-", '').split(' ')[0]
    },
    nextStep() {
      if (!this.sn) {
        return this.$Notify({
          type: 'danger',
          message: '请填写设备号'
        })
      }
      if (!this.sn.split('|')[1]) {
        return this.$Notify({
          type: 'danger',
          message: '请填写正确的设备号'
        })
      }
      this.finalSN = 'Deeper-Wire-DPRB-' + this.sn
      this.$emit('next');
      

    }
  }
}
</script>

<style lang="less" scoped>
.step1container {
  .inputSNBox {
    text-align: center;
  }
}
</style>